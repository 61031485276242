import { useCallback, useEffect, useState } from 'react'
import {
  gql,
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client'

import { usePageVisibility } from 'src/hooks/usePageVisibility'

import {
  GetUnReadNotifications,
  GetUnReadNotificationsVariables,
  MarkAllUserNotificationsRead,
  MarkAllUserNotificationsSeen,
  MarkUserNotificationRead,
  MarkUserNotificationReadVariables,
  SubscriptionUserNotifications,
} from './__generated__/types'
import { FrgUserForLink } from './user'

export const FrgTicketInfo = gql`
  fragment FrgTicketInfo on TicketDef {
    id
    ticketType {
      id
      name
    }
    container {
      id
      containerId
    }
    location {
      name
    }
    container {
      containerId
    }
    status
  }
`

export const FrgEventInfo = gql`
  fragment FrgEventInfo on UserNotificationType {
    id
    createdAt
    dateRead
    user {
      id
    }
    event {
      ... on TicketEventDef {
        id
        ticket {
          ...FrgTicketInfo
        }
        eventType
        createdAt
        createdBy {
          ...FrgUserForLink
        }
        fromStatus
        toStatus
        fromAssignee {
          ...FrgUserForLink
        }
        toAssignee {
          ...FrgUserForLink
        }
        fromAssignedTeam {
          id
          name
        }
        toAssignedTeam {
          id
          name
        }
        fromPriority
        toPriority
        barcodeFileUrl
        company {
          id
        }
        createdByAction
        eventType
        __typename
      }
      ... on TicketMessageDef {
        id
        createdAt
        sender {
          ...FrgUserForLink
        }
        createdBy {
          ...FrgUserForLink
        }
        receiverPropertyUser {
          id
        }
        body
        attachments {
          id
        }
        __typename
        ticket {
          ...FrgTicketInfo
        }
      }
      ... on TicketCommentDef {
        id
        createdAt
        author {
          ...FrgUserForLink
        }
        body
        attachments {
          id
        }
        ticket {
          ...FrgTicketInfo
        }
      }
    }
  }
`

export const GET_ALL_GROUPED_USER_NOTIFICATIONS = gql`
  ${FrgTicketInfo}
  ${FrgUserForLink}
  ${FrgEventInfo}
  query GetAllGroupedUserNotifications(
    $first: Int
    $offset: Int
    $unread: Boolean
    $createdAtFrom: DateTime
    $createdAtTo: DateTime
    $followingOnly: Boolean
    $directOnly: Boolean
  ) {
    allUserEventsNotifications(
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
      first: $first
      unread: $unread
      offset: $offset
      followingOnly: $followingOnly
      directOnly: $directOnly
    ) {
      edges {
        ... on TicketDef {
          ...FrgTicketInfo
          createdAt
          notificationsLog {
            edges {
              ...FrgEventInfo
            }
          }
        }
      }
    }
  }
`

export const GET_ALL_USER_NOTIFICATIONS = gql`
  ${FrgTicketInfo}
  ${FrgUserForLink}
  ${FrgEventInfo}
  query GetAllUserNotifications(
    $first: Int
    $offset: Int
    $orderBy: String
    $order: String
    $unread: Boolean
    $createdAtFrom: DateTime
    $followingOnly: Boolean
    $directOnly: Boolean
  ) {
    allUserNotifications(
      first: $first
      orderBy: $orderBy
      order: $order
      unread: $unread
      offset: $offset
      createdAtFrom: $createdAtFrom
      followingOnly: $followingOnly
      directOnly: $directOnly
    ) {
      totalCount
      edges {
        node {
          ...FrgEventInfo
        }
      }
    }
  }
`

export const GET_UNREAD_NOTIFICATIONS_COUNT = gql`
  query GetUnReadNotifications {
    userNotificationsCounter {
      directNotificationsCount
      followingNotificationsCount
      totalCount
    }
  }
`

export const MARK_ALL_USER_NOTIFICATIONS_READ = gql`
  mutation MarkAllUserNotificationsRead {
    markAllUserNotificationsRead {
      status
    }
  }
`

export const MARK_ALL_USER_NOTIFICATIONS_SEEN = gql`
  mutation MarkAllUserNotificationsSeen(
    $directOnly: Boolean
    $followingOnly: Boolean
  ) {
    markAllUserNotificationsSeen(
      directOnly: $directOnly
      followingOnly: $followingOnly
    ) {
      status
    }
  }
`

export const MARK_USER_NOTIFICATION_READ = gql`
  mutation MarkUserNotificationRead($id: ID!) {
    markUserNotificationRead(id: $id) {
      userNotification {
        dateRead
      }
    }
  }
`

export const SUBSCRIBE_NOTIFICATIONS = gql`
  subscription SubscriptionUserNotifications {
    onUserNotification {
      userNotification {
        id
        createdAt
        dateRead
        user {
          id
          username
        }
      }
    }
  }
`

export const useUnReadNotifications = () => {
  const { data, ...rest } = useQuery<
    GetUnReadNotifications,
    GetUnReadNotificationsVariables
  >(GET_UNREAD_NOTIFICATIONS_COUNT)

  return {
    data: data?.userNotificationsCounter || {
      totalCount: 0,
      followingNotificationsCount: 0,
      directNotificationsCount: 0,
    },
    ...rest,
  }
}

export const useMarkAllNotifications = () => {
  const [markAllRead] = useMutation<MarkAllUserNotificationsRead>(
    MARK_ALL_USER_NOTIFICATIONS_READ,
    { refetchQueries: [GET_UNREAD_NOTIFICATIONS_COUNT] }
  )

  const [markAllSeen] = useMutation<MarkAllUserNotificationsSeen>(
    MARK_ALL_USER_NOTIFICATIONS_SEEN,
    { refetchQueries: [GET_UNREAD_NOTIFICATIONS_COUNT] }
  )

  return [markAllRead, markAllSeen] as const
}

export const useMarkNotification = () => {
  return useMutation<
    MarkUserNotificationRead,
    MarkUserNotificationReadVariables
  >(MARK_USER_NOTIFICATION_READ, {
    refetchQueries: [GET_UNREAD_NOTIFICATIONS_COUNT],
  })
}

export const useSubscribeToNotifications = () => {
  const client = useApolloClient()
  const isVisible = usePageVisibility()
  const [isNewEvent, setIsNewEvent] = useState(false)

  const refetchNotificationsData = useCallback(() => {
    client.refetchQueries({
      include: [
        GET_UNREAD_NOTIFICATIONS_COUNT,
        GET_ALL_USER_NOTIFICATIONS,
        GET_ALL_GROUPED_USER_NOTIFICATIONS,
      ],
    })
  }, [client])

  // This effect limits refetching notifications for inactive tabs, see IPFE-10386
  useEffect(() => {
    if (!isVisible || !isNewEvent) return
    refetchNotificationsData()
    setIsNewEvent(false)
  }, [isVisible, isNewEvent, refetchNotificationsData])

  return useSubscription<SubscriptionUserNotifications>(
    SUBSCRIBE_NOTIFICATIONS,
    {
      onData: () => {
        if (isVisible) refetchNotificationsData()
        else setIsNewEvent(true)
      },
    }
  )
}
